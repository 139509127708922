<template>
	<ValidationObserver ref="form_login" v-slot="{ invalid }">
		<form onsubmit="return false">
			<section class="container">
				<Card :loading="loading">
					<div>
						<h2 class="has-text-centered is-size-3 pt-4">
							Cursos adquiridos
						</h2>
						<h5
							class="has-text-centered my-5"
						>
							{{ subTitle }}
						</h5>
						<h5 v-if="getCodigo" class="has-text-centered m-0">
							{{ email }}
						</h5>
						<div class="form-cpf">
							<div v-if="!getCodigo">
								<ValidationProvider
									v-slot="{ errors }"
									name="CPF"
									rules="required|cpf"
								>
									<b-field
										label="CPF"
										class="m-auto"
										:message="errors[0]"
										:type="errors.length > 0 ? 'is-danger no-icon' : ''"
									>
										<b-input
											class="input-custom"
											v-model="form.cpf"
											v-mask="['###.###.###-##']"
										/>
									</b-field>
								</ValidationProvider>
							</div>
							<div v-if="getCodigo">
								<InputCode v-model="form.code" />
							</div>
							<div class="btns">
								<b-button
									v-if="getCodigo"
									type="is-secondary"
									rounded
									:disabled="isFormInvalid(invalid) || loadingCode"
									@click="sendCode"
								>
									{{ loadingCode ? 'Validando' : 'Continuar' }}
								</b-button>
								<b-button
									v-if="!getCodigo"
									type="is-secondary"
									rounded
									:disabled="isFormInvalid(invalid)"
									@click="submit"
								>
									Continuar
								</b-button>
							</div>
							<div v-if="getCodigo" class="btns" style="margin-top: 0px;">
								<b-button
									type="is-secondary"
									rounded
									outlined
									:disabled="!timerExpired"
									@click="submit"
								>
									Reenviar código
								</b-button>
							</div>
							<h5 v-if="getCodigo" class="has-text-centered m-0">
								<div :class="[
									'has-text-centered',
									'timer',
									'mt-5',
									'mb-6',
									timerExpired ? 'error' : '',
								]">
									<Timer
										v-if="!loading"
										:time="expiration"
										@expired="expired"
									/>
								</div>
								<div v-if="timerExpired" class="mb-6">
									Seu código de autenticação expirou. Clique no botão Reenviar código.
								</div>
							</h5>
							<div  class="mb-4 info">
								<p class="info-verifique">*Verifique também sua caixa de <span>spam</span> e <span>lixo eletrônico</span></p>
							</div>
							<div v-if="getCodigo" class="mt-4 mb-4">
								<p style="display: flex; gap: 10px;">
									Não possui mais acesso ao email?
									<span
										@click="showHelpBox = !showHelpBox"
										class="btn-fale-conosco"
									>
										Fale Conosco
										<img src="@ebradi/assets/img/up.svg">
									</span>
								</p>
							</div>
							<div>
								<transition>
									<div
										v-if="showHelpBox"
										class="mt-4 mb-4 container-help-box"
									>
										<div class="box-whatsapp">
											<div
												class="btn-whatsapp"
												@click="sendToWhatsApp"
											>
												<img src="@ebradi/assets/img/wpp.svg">
												{{ phone_number }}
											</div>
											<div class="btn-icon-zapp">
												<div v-if="showInfo" class="showInfo">
													Número Copiado
												</div>
												<img
													@click="copyNumber"
													src="@ebradi/assets/img/copy.svg"
												>
											</div>
										</div>
										<p class="text-help-box">
											Atendimento de segunda a sexta via Whatsapp<br>
											<span v-if="$store.state.ie.sigla == 'uns'">
												<span class="time">Suporte geral e rematrícula:</span> 8h às 19h
											</span>
											<span v-else-if="$store.state.ie.sigla == 'ebr'">
												<span class="time">Suporte geral:</span> 8h às 17h
											</span>
											<span v-else>
												<span class="time">Suporte geral e rematrícula:</span> 8h às 19h:30
											</span>
											<span v-if="$store.state.ie.sigla != 'ebr'">
												<span class="time">Trancamento e cancelamento:</span> 8h às 18h
											</span>
										</p>
									</div>
								</transition>
							</div>
						</div>
					</div>
				</Card>
				<Recaptcha ref="grecaptcha" />
				<LoadingPage v-model="loading" />
			</section>
		</form>
	</ValidationObserver>
</template>

<script>
import '@commons/rules.js';
import Card from '@ebradi/componets/Card';
import InputCode from '@ebradi/componets/InputCode';
import Recaptcha from '@components/Recaptcha';
import LoadingPage from '@ebradi/componets/LoadingPage';
import Timer from '@components/login/recover/Timer';

import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
	name: "PagaLoginEBRADI",

	components: {
		Card,
		InputCode,
		LoadingPage,
		Recaptcha,
		Timer,
		ValidationObserver,
		ValidationProvider,
	},

	data() {
		return {
			showInfo: false,
			email: '',
			showHelpBox: false,
			getCodigo: false,
			loading: false,
			loadingCode: false,
			loadingResend: false,
			timerExpired: false,
			timer: 0,
			getExpTime: 0,
			timerCount: this.timer,
			subTitle: 'Insira seu CPF e você receberá um código de acesso via email',
			form: {
				cpf: null,
				code: '',
				captcha: '',
			}
		}
	},

	computed: {
		expiration() {
			if (this.getExpTime == null || this.getExpTime <= 0) return 0;

			return this.getExpTime * 60;
		},
		phone_number() {
			return this.$store.state.ie.sigla == 'ebr' ? '(11) 99345-1036' : '(11) 3298-7730';
		}
	},

	watch: {
		timerCount: {
			handler(value) {
				if (value > 0 && !this.resendCode) {
					setTimeout(() => {
						this.timerCount--;
					}, 1000);
				} else {
					this.resendCode = true;
				}
			},
			immediate: true
		},
	},

	mounted() {
		this.loading = true;

		if (!window.grecaptcha) {
			const recaptchaScript = document.createElement('script')

			recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=explicit');
			document.head.appendChild(recaptchaScript);
			recaptchaScript.onload = () => {
				window.grecaptcha.ready(() => {
					this.loading = false;
				});
			};

		} else {
			this.loading = false;
		}
	},

	methods: {
		copyNumber() {
			this.showInfo = true

			navigator.clipboard.writeText(this.zapp)

			setTimeout(() => this.showInfo = false, 1500)
		},

		async submit() {
			this.loading = true;
			this.loadingCode = false;

			try {
				this.form.captcha = await this.$refs.grecaptcha.generate();
			} catch (error) {
				this.toastOpen('Captcha inválido, tente novamente', 'is-danger');

				return;
			}

			this.$store
				.dispatch('ebradi/gerarCodigo', {
					cpf: this.form.cpf,
					captcha: this.form.captcha,
				})
				.then(res => {
					this.getExpTime = res.exp_time;
					this.timerExpired = false;
					this.email = res.seuu_email;
					this.getCodigo = true;

					this.subTitle = (this.getCodigo)
						? 'Insira o código de 4 digitos que você recebeu no email cadastrado*'
						: 'Insira seu CPF e você receberá um código de acesso via email'
				})
				.catch((res) => {
					this.toastOpen(res?.data?.message || 'Erro ao gerar código de acesso', 'is-danger');
				})
				.finally(() => {
					this.loading = false;
				});
		},

		async sendCode() {
			this.loadingCode = true;

			try {
				this.form.captcha = await this.$refs.grecaptcha.generate();
			} catch (error) {
				this.toastOpen('Captcha inválido, tente novamente', 'is-danger');

				return;
			}

			this.$store
				.dispatch('ebradi/validarCodigo', {
					cpf: this.form.cpf,
					captcha: this.form.captcha,
					codigo: this.form.code,
				})
				.then(res => {
					if (res) {
						this.$router.push({name: 'cursos.adquiridos'});
					}
				})
				.catch(() => {
					this.toastOpen('Erro ao validar código de acesso', 'is-danger');
					this.loadingCode = false;
				});
		},

		sendToWhatsApp() {
			if (this.$store.state.ie.sigla == 'ebr') {
				window.open('https://api.whatsapp.com/send?phone=5511993451036&text=Oi Estudante! Clique em enviar para falar conosco!', '_blank');
			} else {
				window.open('https://api.whatsapp.com/send?phone=551132987730&text=Oi Estudante! Clique em enviar para falar conosco!', '_blank');
			}
		},

		isFormInvalid(invalid) {
			return (
				invalid
				|| this.timerExpired
			);
		},

		startTimer() {
			this.timerCount = this.timer;
			this.resendCode = false;
		},

		expired() {
			this.toastOpen('Código expirado!', 'is-danger');
			this.timerExpired = true;
		},

		toastOpen(message, type) {
			this.$buefy.toast.open({
				message,
				type,
				duration: 4000,
			});
		},
	}
}
</script>

<style lang="scss" scoped>
.timer::v-deep span {
	color: var(--primary-300);
}

.timer::v-deep strong {
	color: var(--medium-gray);
}

.timer.error::v-deep strong {
	color: #C93F58;
}

.container {
	padding: 0;
	padding-bottom: 80px;
	width: 704px;
}

h2 {
	line-height: 40px;
	font-size: 32px;
	font-weight: 700;
}

h5 {
	line-height: 24px;
	font-size: 16px;
	font-weight: 400;
}

.input-custom {
	&::v-deep .input {
		background: transparent;
	}
}

.text-active {
	color: var(--turquoise-blue);
}

.form-cpf {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	& > div:first-child {
		width: 280px;
		padding: 42px 0;
	}
}

.btns {
	margin-top: 42px;
	margin-bottom: 16px;

	::v-deep {
		.button {
			width: 220px;
			height: 52px;
		}
	}

}

.info {
	font-size: 12px;
	::v-deep span {
		color: #C93F58;
	}
}

.help-box {
	box-shadow: 1px 7px 4px rgba(0, 0, 0, 0.25);
}

.clickable {
	cursor: pointer;
}

.whats-icon {
	width: 24px;
	height: 24px;
	margin-right: 12px!important;
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}

.btn-fechar {
	display: flex;
	align-items: center;
	justify-content: center;
}

.text-help-box {
	color: #D1D1D1;
	text-align: center;
	font-size: 14px;
	line-height: 24px;
	margin-top: 26px;
	font-weight: 800;

	.time {
		font-weight: 400;
	}
}

.btn-whatsapp {
	width: 100%;
	max-width: 254px;
	margin: auto;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	padding: 10px 0;
	padding-left: 16px;
	padding-right: 36px;
	justify-content: space-between;
	border-radius: 40px;
	border: 1px solid var(--primary);
	cursor: pointer;
}

.box-whatsapp {
	display: flex;
	width: 100%;
	gap: 20px;

	div.btn-icon-zapp {
        position: relative;

        .showInfo {
            background: var(--background-card);
            border-radius: 12px;
            padding: 3px 10px;
            border-left: 2px solid var(--primary);
            text-align: center;
            width: 180px;
            position: absolute;
            top: -30px;
            left: -80px;
            z-index: 2;
            font-weight: 600;
            animation: opacity 0.4s forwards;
        }

        img {
            height: 100%;
            width: 32px;
        }

		cursor: pointer;
	}
}

.btn-fale-conosco {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	color: var(--primary);
	cursor: pointer;
	gap: 4px;
}

.info-verifique {
	span {
		color: #BADEE7!important;
	}
}

@keyframes opacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
</style>
